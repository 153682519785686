<template>
  <div class="pc-bottom-bar">
    <div class="top">
      <div class="top-item">
        <h3>关于集成电路圈</h3>
        <!-- <p @click="gopage">关于我们</p> -->
        <p @click="gopage(1)">关于我们</p>
        <p @click="gopage(2)">联系我们</p>
         <!-- <p @click="gopage(3)">加入我们</p> -->
        <p @click="gopage(4)">合作伙伴</p>
        
      </div>
      <div class="top-item">
        <h3>相关链接</h3>
        <p @click="goUrl(1)">深圳先进电子材料国际创新研究院</p>
   <p @click="goUrl(2)">2024先进半导体产业博览会</p>
        <p  @click="goUrl(3)">先进电子材料创新大会</p>
             <p  @click="goUrl(4)">国际热管理材料技术博览会</p>
       
      </div>
      <div class="top-item">
        <div>
          <h4>扫码关注公众号</h4>
          <p>获取更多资讯和供需信息！</p>
        </div>
        <div>
          <img src="@/assets/images/wxCar.jpg" alt="">
          <img src="@/assets/images/wxCar1.png" alt="">
        </div>
    
      </div>
    </div>
    <div class="bottom">
      Icp备案号 <span @click='goToLink()'>粤ICP备2023045353号</span> 深圳市德泰中研信息科技有限公司 版权所有
    </div>
       
  </div>
</template>
<script>

export default {
  name: "footers",
  
  data() {
    return {};
  },
  methods:{
    gopage(key){
      switch (key) {
        case 1:
          this.$router.push("/aboutus/we")
          break;
            case 2:
          this.$router.push("/aboutus/concat")
          break;
            case 3:
          this.$router.push("/aboutus/join")
          break;
            case 4:
          this.$router.push("/aboutus/cooperation")
          break;
      
        default:
          break;
      }
    },
    goUrl(key){
      switch (key) {
        case 4:
          window.open("http://www.itherm.cn/","_blank ")
          break;
          case 3:
          window.open("https://www.aemic.cn/pc/page/ed680000-8ed7-2e57-60c8-08dbecb01c03","_blank ")
          break;
          case 2:
          window.open("http://aemic.cn","_blank ")
          break;
          case 1:
          window.open("http://www.siem.ac.cn/index.aspx","_blank ")
          break;
      
        default:
          break;
      }


    },
    goToLink(){
window.open("https://beian.miit.gov.cn/","_blank ")
    }
  }
};
</script>

<style lang="less" scoped>
.pc-bottom-bar {
  width: 100%;
  background: white;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 20px;
  .top {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    .top-item {
      flex: 1;
      padding: 20px;
      padding-left: 70px;
      margin-bottom: 20px;
      p{
        cursor: pointer;
      }
      p:hover {
          color: #3d55a7;
        }
    }
    .top-item:nth-child(2) {
      border-left: 2px solid #eaeaea;
      border-right: 2px solid #eaeaea;
    }
     .top-item:nth-child(3) {
      display: flex;
      // align-items: center;
      flex-direction: column;
      padding-left: 70px;
      img{
        width: 90px;
      }
      img:nth-child(1){
        
        position: relative;
        // left: 50px;
        width: 90px;
      }
    }
  }
  .bottom {
    clear: both;
    width: 1280px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding-top: 20px;
    span{
      cursor: pointer;
    }
  }
}
</style>