import requests from "./request";
// requests 为接口地址
import jsonapi from "./getjson"
// jsonapi 为json地址
import dtrequests from "./dtrequest"
import mprequests from "./mprequest"
// 企业库 通讯录数据

// 首页新闻咨询的接口 轮播
// http://api.dtcms.polydt.com/client/article/view/channel/newsCenter/10?SiteId=2&categoryId=9

// 首页近期活动的接口 
// http://api.dtcms.polydt.com/client/article/view/channel/caihuitong/10?SiteId=2&categoryId=7

export const getRecentactivities = () => requests({ url: '/client/article/view/channel/caihuitong/10?SiteId=2&categoryId=7' })

export const getNewsconsulting = () => requests({url:'/client/article/view/channel/newsCenter/10?SiteId=2&categoryId=9'})

//圈子数据
export const Grouplist = () => requests({url:"/client/article/195? PageIndex=1&PageSize=24"})

//专家数据
export const expertslist = (data) => dtrequests({url:"/api/DTExpert/HT_GetDTExpertList2",method:"post",data:data})

//专家列表数据
export const expertslist2 = (PageIndex) => requests({url:`/client/article/187?PageIndex=${PageIndex}&PageSize=8&Fields=id,title,imgUrl,zhaiyao,author,addTime,articleFields`})
//专家列表数据搜索
export const expertslist3 = (PageIndex,keyword) => requests({url:`/client/article/187?PageIndex=${PageIndex}&keyword=${keyword}&PageSize=8&Fields=id,title,imgUrl,zhaiyao,author,addTime,articleFields`})

//获取专家详情
export const getExpertDetail = (id) => dtrequests({url:`/api/DTExpert/HT_GetDTExpertDetailById?id=${id}`})

//获取专家详情
export const getExpertDetail2 = (id) => dtrequests({url:`/api/DTExpert/HT_GetDTExpertDetailById?id=${id}`})

//专家点赞
export const expertLike = (data) => dtrequests({url:"/api/DTLike/DTExpertLike",method:"post",data:data})

//专家取消点赞
export const expertLikeDown = (data) => dtrequests({url:"/api/DTLike/DTExpertLikeDown",method:"post",data:data})

//专家收藏
export const expertFav =(articleId) =>requests({url:`/client/article/fav/${articleId}`,method:"put"})

// export const expertFav = (data) => dtrequests({url:"/api/DTFav/DTExpertFav",method:"post",data:data})

//专家取消收藏
export const expertFavDown = (data) => dtrequests({url:"/api/DTFav/DTExpertFavDown",method:"post",data:data})

// 资讯列表接口：  
export const getConsultationlist =(PageIndex)=> requests({url:`/client/article/184?PageIndex=${PageIndex}&PageSize=8&Fields=id,title,imgUrl,zhaiyao,author,addTime`})

// 资讯分类接口：
export const getConsultationclass =()=>requests({url:"/client/article/184/category?siteId=23"})

// 资讯根据分类去查对应的接口数据
export const getConsultationcategory = (id) =>requests({url:`/client/article/184?PageIndex=1&PageSize=8&CategoryId=${id}`})

// 推荐资讯
export const gethotconsultinglist =()=> requests({url:"/client/article/184?PageIndex=1&PageSize=2&orderBy=-Click"})



// 供需列表接口：
export const getSupplylist = () => requests({url:"/client/article/185?PageIndex=1&PageSize=24"})

// 供需分类接口：
export const getSupplyclassification = ()=> requests({url:"/client/article/185/category?siteId=23"})

// 供需根据分类去查对应的接口数据
export const getSupplyclassifyid = (id) =>requests({url:`/client/article/185?CategoryId=${id}`})
// https://api.dtcms.polydt.com/client/article/76?CategoryId=282

// 热门供需
export const  gethotsupply =(id)=>requests({url:"/client/article/185?PageIndex=1&PageSize=4&labelId=37"})

export const createSupply = (data) => requests({url:"/client/article/contribute",method:"post",data:data})

export const createPDSupply = () => requests({url:"/client/channel/76",method:"get"})


export const updateImage = (data) => requests({url:"/upload?water=0&thumb=0",method:"post",data})


// 公司库列表接口：
// 企业列表接口  
export const getCompanyLibrarylistType = (data) => dtrequests({url:`/api/DTConferenceCompany/GetDTConferenceCompanyTypeList`,params:{
    ConferenceId:25,
    parentId:data
}})
// 企业列表接口  
export const getCompanyLibrarylist = (data) => dtrequests({url:`/api/DTCenter/HT_GetCompanyList`,params:{
    pageIndex:data.pageIndex,
    pageSize:data.pageSize
}})

// 企业列表接口  
export const getDTConferenceCompanyList = (data) => dtrequests({url:`/api/DTConferenceCompany/GetDTConferenceCompanyList?`,params:data})

// 企业详情
export const getDTCompanyDetail = (CompanyId) => dtrequests({url:`/api/DTCompany/HT_GetDTCompanyDetailById?CompanyId=${CompanyId}`
   })

   // 企业详情
export const getDTConferenceCompanyDetailById = (ConferenceCompanyId) => dtrequests({url:`/api/DTConferenceCompany/HT_GetDTConferenceCompanyDetailById?ConferenceCompanyId=${ConferenceCompanyId}`
})

   // 获取企业产品列表
export const getDTExhibitsListByConferenceCompanyId = (ConferenceCompanyId) => dtrequests({url:`/api/DTConferenceCompanyExhibits/GetDTExhibitsListByConferenceCompanyId?ConferenceCompanyId=${ConferenceCompanyId}`
})

   // 获取企业产品列表
   export const getDTExhibitsDetailById = (id) => dtrequests({url:`/api/DTExhibits/GetDTExhibitsDetailById?ExhibitId=${id}`
   
})
   // 获取企业产品点赞列表
   export const GetDTExhibitsDetail = (id) => dtrequests({url:`/api/DTConferenceCompanyExhibits/GetDTExhibitsDetail?ConferenceCompanyExhibitsId=${id}`
})





// 公司库分类接口：
// https://api.dtcms.polydt.com/client/article/108/category?siteId=14
export const getCompanyLibrarytype = () => requests({url:`/client/article/108/category?siteId=14`})

// 公司库公司类型
export const getgklx =()=>dtrequests({url:`/api/DTCenter/HT_GetLabelListByGroup`,params:{
    group:"公司类型"
}})

// 公司库产品类型
export const getgkcplx =()=>dtrequests({url:`/api/DTCenter/HT_GetLabelListByGroup`,params:{
    group:"产品类型"
}})


// 公司库公司类型
export const getgkgslx =()=>dtrequests({url:`/api/DTCenter/HT_GetLabelListByGroup`,params:{
    group:"公司类型"
}})

// 公司库类型
export const getDTConferenceCompanyTypeList =()=>dtrequests({url:`/api/DTConferenceCompany/GetDTConferenceCompanyTypeList`,params:{
    ConferenceId:7
}})


// 公司库公司类型
export const getgklb =()=>dtrequests({url:`/api/DTCenter/HT_GetLabelListByGroup`,params:{
    group:"组织类型"
}})

// 省市区列表
export const getProvinciallsit =(name)=>dtrequests({url:`/api/DTCenter/HT_GetRegionList`,params:{
    name
}})

// 获取标签列表
export const getlabellist=()=>dtrequests({url:`/api/DTCenter/HT_GetLabelList`})


// 通讯录列表接口：
// https://api.dtcms.polydt.com/client/article/75? PageIndex=1&PageSize=24   
// 通讯录分类接口：
// https://api.dtcms.polydt.com/client/article/75/category?siteId=14

// 活动列表接口：
// https://api.dtcms.polydt.com/client/article/109? PageIndex=1&PageSize=24   
export const getactivitylist = (id) =>requests({url:`/client/article/188?PageIndex=1&PageSize=24&CategoryId=${id}&Fields=id,title,imgUrl,articleFields `})
// 活动分类接口：
// https://api.dtcms.polydt.com/client/article/109/category?siteId=14
export const getactivitytype = () =>requests({url:"/client/article/188/category?siteId=23"})

// 活动根据分类去查对应的接口数据
export const  getactivitylisttype =(id)=> requests({url:`/client/article/109?CategoryId=${id}`})



// 课程列表接口：
// https://api.dtcms.polydt.com/client/article/110? PageIndex=1&PageSize=24   
export const  getCourselist =(PageIndex)=> requests({url:`/client/article/189?PageIndex=${PageIndex}&PageSize=6`})

// 课程分类接口：
// https://api.dtcms.polydt.com/client/article/110/category?siteId=14
export const  getCoursetype =()=> requests({url:"/client/article/189/category?siteId=23"})

// 课程根据分类去查对应的接口数据
export const  getCourselisttype =(id)=> requests({url:`/client/article/189?PageIndex=1&PageSize=6&CategoryId=${id}`})

// 课程轮播
export const getClassswiper = ()=> requests({url:"/client/article/110?CategoryId=356"})

// 热门课程
export const gethotclasslist =(id)=> requests({url:`/client/article/${id}?PageIndex=1&PageSize=4&orderBy=-Click`})

//详情接口
export const getdetails =(id)=> requests({url:`/client/article/show/${id}`})

//获取合作伙伴
export const  getCooperationlist =()=> requests({url:`/client/article/199?PageIndex=1&PageSize=99&Fields=id,title,linkUrl,thumb`})

// 活动轮播
export const getactivswiper = (id)=> requests({url:`/client/article/194?CategoryId=${id}`})



// 首页轮播
export const gethomeswiper = ()=> requests({url:"/client/article/194?CategoryId=494"})

// 专家库
export const getzjk = ()=>requests({url:"/client/article/75?%20PageIndex=1&PageSize=24"})

// 搜索
export const getsearch =(name)=>requests({url:`/client/news/article?siteId=1&categoryId=0&labelId=0&pageSize=10&pageIndex=1&keyword=${name}`})

// 获取验证码
export const getVerificationCode = () => requests({url:'/verifycode'})

//登录
export const getloginin =(data)=> requests({url:"/auth/login",method:"post",data:data})
//微信登录
export const getWxloginin =(data)=> requests({url:"/oauth/login",method:"post",data:data})

//获取用户信息
export const getuserinfo =()=> requests({url:'/account/member'})

// 注册
export const getregister = (data)=>requests({url:"/account/member/register",method:"post",data}) 

// 二维码登录
export const getwxcode =(uri)=>requests({url:`/oauth/redirect?siteId=23&provider=wechat&redirectUri=${uri}`})

// 发送短信
export const getsendphone =(phone)=>requests({url:`/verifycode/mobile/${phone}`})

// 重置密码
export const getResetuser =()=>requests({url:`/auth/reset`})
//获取用户信息

// 优惠券
export const getcoupon = (params)=>requests({url:"/account/coupon/history",params})

// 新建企业
export const getcreateenterprise = (data)=>requests({url:"/client/creatCompany",method:"post",data})

// 获取公司信息
export const geterprisedetails = (params)=>requests({url:"/client/info",params})

// 公司详情
export const getcompanyInfo = (id) =>requests({url:`/client/companyInfo/${id}`})

// 订单管理
export const getcontractview =() =>requests({url:"/client/contract/view/0"})

// 优惠券转赠
export const getallocationmember =(data) =>requests({url:"/client/allocation/member",method:"post",data})

// 优惠券信息
export const getallocationlist = () =>requests({url:"/client/allocation/list/13"})

// 搜索转赠用户
export const getfreeuser =(value) =>requests({url:`/client/member/${value}`})

// 更新点赞量（文章id
export const putLike =(articleId) =>requests({url:`/client/article/like/${articleId}`,method:"put"})

// 更新收藏量（文章id
export const putFav =(articleId) =>requests({url:`/client/article/fav/${articleId}`,method:"put"})

// 获取收藏量
export const fav =() =>requests({url:`/client/article/favlist/23`,method:"put"})

// 企业收藏列表
export const getDTConferenceCompanyFavList =(data) =>dtrequests({url:"/api/DTFav/GetDTConferenceCompanyFavList",method:"post",data})


//专家收藏数据
export const expertFavList = (data) => dtrequests({url:"/api/DTFav/GetDTExpertFavList",method:"post",data:data})

//企业点赞
export const companyLike = (data) => dtrequests({url:"/api/DTLike/DTConferenceCompanyLike",method:"post",data:data})
//企业取消点赞
export const companyLikeDown = (data) => dtrequests({url:"/api/DTLike/DTConferenceCompanyLikeDown",method:"post",data:data})

//企业收藏
export const companyFav = (data) => dtrequests({url:"/api/DTFav/DTConferenceCompanyFav",method:"post",data:data})

//企业取消收藏
export const companyFavDown = (data) => dtrequests({url:"/api/DTFav/DTConferenceCompanyFavDown",method:"post",data:data})

//产品点赞
export const exhibitsLike = (data) => dtrequests({url:"/api/DTLike/DTConferenceCompanyExhibitsLike",method:"post",data:data})
//产品取消点赞
export const exhibitsLikeDown = (data) => dtrequests({url:"/api/DTLike/DTConferenceCompanyExhibitsLikeDown",method:"post",data:data})

//产品收藏
export const exhibitsFav = (data) => dtrequests({url:"/api/DTFav/DTConferenceCompanyExhibitsFav",method:"post",data:data})

//产品取消收藏
export const exhibitsFavDown = (data) => dtrequests({url:"/api/DTFav/DTConferenceCompanyExhibitsFavDown",method:"post",data:data})

//专家收藏数据
export const exhibitsFavList = (data) => dtrequests({url:"/api/DTFav/GetDTConferenceCompanyExhibitsFavList",method:"post",data:data})

// 圈子获取详情
export const getCircleDetail = (id) =>mprequests({url:`/circle/circleInfoOpen/${id}`, method: 'get',headers: {'clientKey':"admin",'clientPwd':"123"
    
  }})