
<template>
  <div id="app" v-cloak>
    <template v-if="$route.meta.keepAlive">
      <Topheader></Topheader>
      <router-view></router-view>
      <Footer></Footer>
    </template>

    <template v-if="!$route.meta.keepAlive">
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import Topheader from "@/components/Topheader";
import Footer from "@/components/Footer";

export default {
  name: "App",
  data() {
    return {
      mUrl: 'http://siem-icmlab.ac.cn',
      pcUrl: 'http://test.mcnki.net',
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
    };
  },
  components: {
    Topheader,
    Footer,

  },
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  },
  mounted() {

    // window.onresize = () => {
    //   return (() => {
    //     window.fullWidth = document.documentElement.clientWidth;
    //     this.windowWidth = window.fullWidth;

    //     if( this.windowWidth<1280){
    //       window.open(this.mUrl,"_self")
    //     }
    //   })();
    // };


  }
};
</script>

<style lang="less">
[v-cloak] {
  display: none;
}
</style>
